import React from 'react';
import Container from '@material-ui/core/Container';
import Layout from '../components/layout';

const effectivePrivacyDate = 'Nov 30, 2020';

export default function Privacy() {
  return (
    <Layout>
      <div className='root' style={{ paddingTop: 80 }}>
        <Container maxWidth='lg'>
          <h1>Privacy Policy</h1>
          <p><em>Last updated: {effectivePrivacyDate}</em></p>
          <p>This Privacy Policy (“Policy”) describes how TabManager.io (“we,” or “us”) collects, uses and discloses your personal information. This Policy applies to information we collect when you use our websites, online products and services where this Policy is posted (collectively, the “Services”).</p>

          <p>This Privacy Policy is effective as of {effectivePrivacyDate} and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.</p>

          <p>We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Services after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.</p>

          <p>If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing a prominent notice on our website, and provide you with an opportunity to opt-out of the Services before using or sharing any previously collected information.</p>

          <h3>Information That You Provide to Us</h3>
          <p>When you register for an account to access or utilize the Services (an “Account”), we ask for and may collect personally identifiable information about you such as your name or your company, postal address, avatar/photograph, phone number, email address, credit card or other payment method information (collectively, an “Account Information”). By providing us with Account Information, you represent that you are the owner of such personal data or otherwise have the requisite consent to provide it to us.</p>

          <h3>Third Party Channels</h3>
          <p>We may provide an option to access or register for the Services through the use of your user name and passwords for certain services provided by third parties (each, an “Third Party Channels”), such as through the use of your Google account. By authorizing us to connect with a Third Party Channels, you authorize us to access and store the information that the Third Party Channels makes available to us, and to use and disclose it in accordance with this Policy. It is your responsibility to check your privacy settings for such Third Party Channels (please review the terms of use and privacy policy of such Third Party Channels) to control what information is available to us.</p>

          <h3>Usage Analytics</h3>
          <p>We may use analytics tools such as Google Analytics to collect, monitor and analyze data to improve our Services and user experience. More information about how Google Analytics is used by us can be found here: <a href='https://www.google.com/analytics/learn/privacy.html'>https://www.google.com/analytics/learn/privacy.html</a>. To provide website visitors with more choice on how their data is collected by Google Analytics, Google has developed the Google Analytics Opt-out Browser Add-on. The add-on communicates with the Google Analytics JavaScript (ga.js) to indicate that information about the website visit should not be sent to Google Analytics. The Google Analytics Opt-out Browser Add-on does not prevent information from being sent to the Online Services or to other web analytics services.</p>

          <h3>How We Use Your Information</h3>
          <ul>
            <li>We use personal information collected through our Services for purposes described in this Policy or otherwise disclosed to you on or in connection with our Services. For example, we may use your information to:
              <ul>
                <li>Operate and improve our Services;</li>
                <li>Send you newsletters, marketing or promotional materials and other information of interest.</li>
                <li>Provide and deliver the products and services you request, process transactions, and to send you related information, including confirmations and invoices;</li>
                <li>Send you technical notices, updates, security alerts and support and administrative messages;</li>
                <li>Respond to your comments, questions and requests and provide customer service;</li>
                <li>Monitor and evaluate trends, usage and activities in connection with our Services;</li>
              </ul>
            </li>
            <li>The use of information received from Google APIs will adhere to the <a href="https://developer.chrome.com/webstore/program_policies" target="_blank" rel="noreferrer">Chrome Web Store User Data Policy</a>, including the <a href="https://developer.chrome.com/webstore/program_policies#limited_use" target="_blank" rel="noreferrer">Limited Use</a> requirements.</li>
          </ul>

          <h3>Information Sharing</h3>
          <p><b>We respect your privacy and therefore we never sell access to your data. That includes your searches, your browsing history, and your personal information.</b></p>

          <p>We share information, including personal information, with our third-party service providers that we use to provide hosting for and maintenance of the Services. These third-party service providers may have access to or process your personal information for the purpose of providing these services for us. We do not permit our third-party service providers to use the personal information that we share with them for their marketing purposes or for any other purpose than in connection with the services they provide to us.</p>

          <ul>
            <li>We may also share your personal information with third parties in the following limited circumstances:
              <ul>
                <li>When we have your consent.</li>
                <li>With law enforcement, courts of competent jurisdiction, or others when we have a good faith belief that access, use, preservation or disclosure of such information is reasonably necessary to (a) satisfy any applicable law, regulation, legal process or enforceable governmental request, (b) enforce applicable Terms of Services, including investigation of potential violations thereof, (c) detect, prevent, or otherwise address fraud, security or technical issues, or (d) protect against harm to the rights, property or safety of TabManager.io, its users or the public as required or permitted by law.</li>
              </ul>
            </li>
          </ul>

          <h3>How Long We Retain Your Personal Information</h3>
          <p>We will retain your personal information for as long as is needed to fulfill the purposes outlined in this Policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible. For personal information that we process on behalf of our Users, we will retain such personal information in accordance with the terms of our agreement with them, subject to applicable law.</p>

          <h3>Marketing</h3>
          <p>If you have agreed to receive marketing, you may always opt out at a later date.</p>

          <h3>Cookies</h3>
          <p>Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our websites, we may collect information from you automatically through cookies or similar technology. For further information, visit allaboutcookies.org.</p>
          <ul>
            <li>Our Services does not use these “cookies” explicitly. However, services may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of our Services.</li>
          </ul>

          <h3>Data Security</h3>
          <p>The security of your personal information is important to us. We follow generally accepted standards to protect the personal information submitted to us, both during transmission and once it is received. If we learn of a security systems breach, we will inform you and the authorities of the occurrence of the breach in accordance with applicable law.</p>

          <h3>Your Privacy Rights</h3>
          <ul>
            <li>You may terminate your use of our services at any time by sending an email to privacy@tabmanager.io.</li>
          </ul>
          <p>Upon request we will provide you with information about whether we hold, or process on behalf of a third party, any of your personal information. To request this information please contact us at privacy@tabmanager.io.com. Users may update or change their Account Information by editing their profile within the Services. To make a request to have personal information maintained by us returned to you or removed, please send an email to privacy@tabmanager.io. Requests to access, change, or remove your information will be handled within thirty (30) days; provided that, notwithstanding such request, this information may be retained for as long as you maintain an account for the Services, or as needed to provide you with Services, comply with our legal obligations, resolve disputes and enforce our agreements. Upon receipt of a request from one of our Users for us to remove the data, we will respond to their request within thirty (30) days. We will retain personal information that we store and process on behalf of our Users for as long as needed to provide the Services to our Users. We will retain and use this personal information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>

          <h3>Children’s Privacy</h3>
          <p>These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided me with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us at privacy@tabmanager.io and we will use commercially reasonable efforts to delete that information..</p>

          <h3>Other Websites</h3>
          <p>Our Services may contain links to other websites. This privacy policy only applies to our Services so when you link to other websites you should read their own privacy policies.</p>

          <h3>Contact Us</h3>
          <ul>
            <li>If you have questions or concerns regarding this Policy, please contact TabManager.io at: <a href="mailto:privacy@tabmanager.io">privacy@tabmanager.io</a></li>
          </ul>
        </Container>
      </div>
    </Layout>
  );
}
